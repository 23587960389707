
<!-- <app-header></app-header> -->
<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
<!-- gg -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "line-scale" [fullScreen] = "true"></ngx-spinner>
<script>
$('.count').each(function () {
    $(this).prop('Counter',0).animate({
        Counter: $(this).text()
    }, {
        duration: 3000,
        easing: 'swing',
        step: function (now) {
            $(this).text(Math.ceil(now));
        }
    });
});
</script>