import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { CommonService } from '../../commonService/common.service';
import { Observable, tap } from 'rxjs';
import { Storage_Keys } from '../storage/storage.keys';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{

      constructor(private storage: StorageService,private router: Router,private commonService: CommonService
      ) { }
    
      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const savedData = this.storage.getSavedData();
        let header: any = {};        
  
        if (savedData && savedData.authToken) {
          header["Authorization"]= savedData.authToken;
        }
    
        const updatedRequest = request.clone({
          headers: new HttpHeaders(header)
        });
        return next.handle(updatedRequest).pipe(
          tap(
            (event: HttpEvent<any>) => {
            }, (error) => {
              if (error instanceof HttpErrorResponse) {
                if (error.status == 401) {
                  this.storage.clearStorageForLogout();
                  this.commonService.showWarningToastr('Session expired!'); 
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
              }
            }
          )
        );
      }
  }



  // implements HttpInterceptor {
  //   constructor(private storage: StorageService,private router: Router,private commonService: CommonService
  //     ) { }
    
  //     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //       const savedData = this.storage.getSavedData();
        
  //       let header = {};
  
  //       if (savedData && savedData[Storage_Keys['userData']]) {
  //         header = {
  //           "Authorization": savedData[Storage_Keys['userData']].authToken
  //         };
  //       }
    
  //       const updatedRequest = request.clone({
  //         headers: new HttpHeaders(header)
  //       });
  //       return next.handle(updatedRequest).pipe(
  //         tap(
  //           (event: HttpEvent<any>) => {
  //           }, (error) => {
  //             if (error instanceof HttpErrorResponse) {
  //               if (error.status == 401) {
  //                 this.storage.clearStorageForLogout();
  //                 // this.commonService.errorToast('Session expired!'); 
  //                 localStorage.clear();
  //                 this.router.navigate(['/login']);
  //               }
  //             }
  //           }
  //         )
  //       );
  //     }
