const supplier = '/supplier/v1/'
export const Endpoints = {
  "auth": {
    customer: {
      "login": `/customer/v1/login`,
      "logout": `/customer/v1/logout`,
      "resetPassword": `/customer/v1/reset-password`,
      "forgotPassword": `/customer/v1/forgot-password`,
      "otpVerify": `/customer/v1/otp-verify`,
      "register": "/customer/v1/register",
      "institution": "/customer/v1/institution",
      "profession": "/customer/v1/profession",
    },
    supplier: {
      "login": `${supplier}login`,
      "logout": `${supplier}logout`,
      "resetPassword": `${supplier}reset-password`,
      "forgotPassword": `${supplier}forgot-password`,
      "otpVerify": `${supplier}otp-verify`,
    },
  },
  'supplier': {
    "register": {
      "stepOne": `${supplier}register/stepOne`,
      "stepTwo": (stepOneId: string) => { return `${supplier}register/stepTwo/${stepOneId}` },
      "stepThree": (stepTwoId: string) => { return `${supplier}register/stepThree/${stepTwoId}` },
      "stepFour": (stepThreeId: string) => { return `${supplier}register/${stepThreeId}` },
      "edit": (stepThreeId: string) => { return `${supplier}edit-request/${stepThreeId}` },
    },
    "stepData": {
      "register": (stepsId: string) => { return `${supplier}register/${stepsId}` },
    },
    "productCategory": {
      'list': `${supplier}product-category`
    },
    'imageUpload': {
      upload: `${supplier}upload-supplier`
    },
    order: {
      list: "/supplier/v1/order",
      updateStatus: (orderId: string) => { return `/supplier/v1/order-status/${orderId}` },
      sendConfirmationMail: "/supplier/v1/send-confirmation-mail",
      orderAttachment: (orderId: string) => { return `/supplier/v1/order-attachment/${orderId}` },
    },
    query: {
      list: "/supplier/v1/query",
      respond: "/common/v1/query-request-seller"
    },
    count: (customerId: string) => { return `${supplier}count-list/${customerId}` },
    "updateEmail": "/supplier/v1/update-email"
  },
  "productsAndServices": {
    "categoryList": "/supplier/v1/product-categories",
    "subCategoryList": "/supplier/v1/product-sub-categories",
    "classificationList": "/admin/v1/product-classifications",
    "addProducts": "/supplier/v1/product",
    "addServices": "/supplier/v1/service",
    "pendingList": (page: Number, limit: Number, supplierId: string) => { return `${supplier}pending-request/${supplierId}?page=${page}&limit=${limit}` },
    "productList": (page: Number, limit: Number, supplierId: string) => { return `${supplier}approved-request/${supplierId}?page=${page}&limit=${limit}` },
    "productDetails": (productId: string) => { return `${supplier}product/${productId}` },
    "serviceDetails": (productId: string) => { return `${supplier}service/${productId}` },
    "servicesList": (page: Number, limit: Number, supplierId: string) => { return `${supplier}pending-product/${supplierId}?page=${page}&limit=${limit}` },
    "orderReceived": (page: Number, limit: Number, supplierId: string) => { return `${supplier}pending-product/${supplierId}?page=${page}&limit=${limit}` },
    "productDelete": (productId: string) => { return `${supplier}product-delete/${productId}` },
    "serviceDelete": (serviceId: string) => { return `/supplier/v1/service-delete/${serviceId}` },
    "serviceUpdate": (serviceId: string) => { return `${supplier}service/${serviceId}` },
    "productUpdate": (productId: string) => { return `${supplier}product/${productId}` },
    "update": (serviceId: string) => { return `/supplier/v1/service/${serviceId}` },

  },
  common: {
    "banners": "/customer/v1/banner-list",
    "faqs": "/customer/v1/faqs-list",
    "videos": "/customer/v1/video-list",
    "support": "/common/v1/support",
    "queryDetail": (queryId: string) => { return `/common/v1/query/${queryId}` },
    "queryDetailForSeller": (queryId: string) => { return `/supplier/v1/query/${queryId}` },
    "content": "/common/v1/content",
    "mrkCncl": (queryId: string) => { return `/common/v1/query-status/${queryId}` },
    "orderDetail": (orderId: string) => { return `/common/v1/order/${orderId}` },
    "states": '/common/v1/states',
    "city": (countryCode: string, isoCode: string) => { return `/common/v1/cities-by-state?countryCode=${countryCode}&isoCode=${isoCode}` },
    "reviewsList": "/common/v1/all-review-list",
    "pushNotificationRegister": "/common/v1/subscribe",
    "search": "/common/v1/search",
    notification: {
      "list": "/common/v1/notification/list",
      "delete": (notificationId: string) => { return `/common/v1/notification/delete/${notificationId}` },
      "markRead": (notificationId: string) => { return `/common/v1/notification/read/${notificationId}` },
      "markAllRead": "/common/v1/notification/readAll"
    }
  },
  "customer": {
    "products": {
      "categories": "/customer/v1/categories",
      "productsByCategories": "/customer/v1/category-products",
      "prodoctDetail": (productId: string) => { return `/customer/v1/product/${productId}` },
      "productListing": "/customer/v1/view-product-by-category",
    },
    services: {
      listing: "/customer/v1/view-service-by-category"
    },
    cart: {
      add: "/customer/v1/addCart",
      get: "/customer/v1/getCartDetails",
      delete: (cartId: string) => { return `/customer/v1/cart/${cartId}` }
    },
    query: {
      sendQuery: "/customer/v1/query",
      list: "/customer/v1/query",
      queryDetail: (queryId: string) => { return `/common/v1/query/${queryId}` },
      respond: "/common/v1/query-request"
    },
    order: {
      placeOrder: "/customer/v1/order",
      orders: "/customer/v1/order",
      orderDetail: (orderId: string) => { return `/common/v1/order/${orderId}` },
      cancelOrder: (orderId: string) => { return `/customer/v1/cancelOrder/${orderId}` },
      returnOrder: "/customer/v1/return-order",
      orderPayment: "/customer/v1/initiate-payment",
      getInvoice: (orderId: string) => { return `/customer/v1/get-invoice/${orderId}` },
      requestInstallation: "/customer/v1/installation-order-request",
      fetchDeliveryCharges: "/customer/v1/serviceability",
      checkShippingAvailability: "/common/v1/checkAvailability"
    },
    address: {
      addressList: "/customer/v1/address",
      addAddress: "/customer/v1/address",
      setAsDefault: (addressId: string) => { return `/customer/v1/default-address/${addressId}` },
      deleteAddress: (addressId: string) => { return `/customer/v1/address/${addressId}` },
      updateAddress: (addressId: string) => { return `/customer/v1/address/${addressId}` },
    },
    profile: {
      updateProfile: (userId: string) => { return `/customer/v1/profile/${userId}` },
      profileDetail: "/customer/v1/profile",
      changePassword: "/customer/v1/change-password",
      changeResetPassword: "/supplier/v1/reset-password",
      "updateEmail": "/customer/v1/update-email"
    },
    count: (customerId: string) => { return `/customer/v1/count-list/${customerId}` },
    achievement: "/customer/v1/achievement-list",
    wishlist: {
      addRemove: "/customer/v1/wishlist",
      get: "/customer/v1/wishlist"
    },
    'imageUpload': {
      upload: '/customer/v1/upload-customer'
    },
    'review': {
      rating: '/customer/v1/add-review',
      view: `/customer/v1/review-list`,
      updateReview: (reviewId: string) => { return `/customer/v1/update-review/${reviewId}` }
    },
  },
  read: {
    "read-seller-order": (id:string) => { return `/supplier/v1/read-seller-order/${id}` },// seller
    "acceptInstallationCompleted": "/supplier/v1/complete-seller-installation",

    // "read-service": (id:string) => { return `/read-service/${id}` },//service
    // "read-order": (id:string) => { return `/read-order/${id}` }, //order
    // "read-buyer-query": (id:string) => { return `/read-buyer-query/${id}` },// buyer query
    // "read-seller-query": (id:string) => { return `/read-seller-query/${id}` },// seller query
    // "read-support": (id:string) => { return `/read-support/${id}` },// support
    // "read-review": (id:string) => { return `/read-review/${id}` },// review
    // // "readCount": `/count-unread-list`,// for management count
  }
}
// '/customer/v1/review-list?customerId&orderId'