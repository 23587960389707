import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { UserRole } from './constants/user.roles';



const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '_',
    loadChildren: () => import('./modules/userModule/user.module').then(m => m.UserModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authenticationModule/authentication.module').then(m => m.AuthenticationModule),
    data: { type: 'preLogin', roles: [UserRole.customer, UserRole.supplier] },
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./modules/paymentModule/payment.module').then(m => m.PaymentModule),
    data: { type: 'postLogin', roles: [UserRole.customer, UserRole.supplier] },
    canActivate: [AuthGuard]
  },
  {
    path: 'seller',
    loadChildren: () => import('./modules/suppliermodule/supplier.module').then(m => m.SupplierModule),
    data: { type: 'both', roles: [UserRole.supplier] },
    canActivate: [AuthGuard]
  },
  {
    path: 'info',
    loadChildren: () => import('./modules/static-pages/static-pages.module').then(m => m.StaticPagesModule),
    data: { type: 'both', roles: [UserRole.customer, UserRole.supplier] },
    // canActivate: [AuthGuard]
  },
  {
    path: "**",
    redirectTo: ''
  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
