import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(private http:HttpClient) { }

  private generateRequestUrl(endpoint:string){
    return environment.baseUrl + endpoint;
  }

  private generateHttpHeaders(options?: HttpInputData) {
		let headers = {};
		if (options) {
			headers = { headers: options.headers, params: options.params }
		}
		return headers;
	}




	//  Post Api function **//
	post(endpoint: string, payload: any, options?: HttpInputData): Observable<any> {
		return this.http.post(this.generateRequestUrl(endpoint), payload, this.generateHttpHeaders(options));
	}

	//  Get Api function **//
	get(endpoint: string, options?: HttpInputData): Observable<any> {
		return this.http.get(this.generateRequestUrl(endpoint), this.generateHttpHeaders(options));

	}

	//  Put Api function **//
	put(endpoint: string, payload: any, options?: HttpInputData): Observable<any> {
		return this.http.put(this.generateRequestUrl(endpoint), payload, this.generateHttpHeaders(options));
	}

  // delete API function
	delete(endpoint: string, options?: HttpInputData): Observable<any> {
		return this.http.delete(this.generateRequestUrl(endpoint), this.generateHttpHeaders(options));
	}

  request(method: "delete" | "put" | "post" | "get", url: { url?: string; endpoint: string }, reportProgress?: boolean, body?: any, options?: HttpInputData) {
		return this.http.request(method, url.url || this.generateRequestUrl(url.endpoint), { body: body, headers: this.generateHttpHeaders(options), "reportProgress": reportProgress });
	}


 
}
export class HttpInputData {
	headers: HttpHeaders =null as any;
	params: HttpParams = null as any;
	authentication: boolean = null as any;
}
