import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import { HttpInputData, HttpRequestService } from '../core/http/http-request.service';
import { HttpParams, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from '../apiendpoints/endpoints';
import { Session_Storage_Keys, Storage_Keys } from '../core/storage/storage.keys';
import { StorageService } from '../core/storage/storage.service';
import { SearchQuery, Support } from 'src/app/constants/common.model';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  addedToCartSignal: Subject<boolean> = new Subject<boolean>();
  addedToCompareSignal: Subject<boolean> = new Subject<boolean>();
  headerRefresh: Subject<boolean> = new Subject<boolean>();

  //---------------Event call between two components without useing selector----------------//
  private eventSubject = new Subject<any>();
  event$ = this.eventSubject.asObservable();



  constructor(
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private httpWrapper: HttpRequestService,
    private storage: StorageService,
    private http: HttpClient
  ) { }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // Method to publish events
  publishEvent(event: any) {
    this.eventSubject.next(event);
  }

  showWarningToastr(message: string, title?: string) {
    this.toastrService.warning(message, title, {
      positionClass: "toast-bottom-center"
    })
  }
  showSuccessToastr(message: string, title?: string) {
    this.toastrService.success(message, title, {
      positionClass: "toast-bottom-center"
    })
  }
  notification(message: string, title?: string) {
    this.toastrService.success(message, title, {
      positionClass: "toast-top-right"
    })
  }

  getBanners(): Promise<Array<any>> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.banners, httpInput).subscribe(
        ({ bannerList }) => {
          resolve(bannerList || []);
        },
        (error: HttpErrorResponse) => {
          resolve([]);
        }
      );
    });
  }

  getFAQs(): Promise<Array<any>> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.faqs, httpInput).subscribe(
        ({ faqList }) => {
          resolve(faqList || []);
        },
        (error: HttpErrorResponse) => {
          resolve([]);
        }
      );
    });
  }

  getVideos(): Promise<Array<any>> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.videos, httpInput).subscribe(
        ({ video }) => {
          resolve(video || []);
        },
        (error: HttpErrorResponse) => {
          resolve([]);
        }
      );
    });
  }

  getCustomerDataCounts(): Promise<any> {
    let savedData = this.storage.getSavedData();
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.customer.count(savedData[Storage_Keys.userData]._id), httpInput).subscribe(
        ({ countData }) => {
          resolve(countData || null);
        },
        (error: HttpErrorResponse) => {
          resolve(null);
        }
      );
    });
  }
  getSupplierDataCounts(): Promise<any> {
    let savedData = this.storage.getSavedData();
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.supplier.count(savedData[Storage_Keys.userData]._id), httpInput).subscribe(
        ({ countData }) => {
          resolve(countData || null);
        },
        (error: HttpErrorResponse) => {
          resolve(null);
        }
      );
    });
  }
  getQueryDetail(queryId: string): Promise<any> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.queryDetail(queryId), httpInput).subscribe(
        ({ queryList }) => {
          if (queryList && queryList.queryResponse && queryList.queryResponse.length) {
            let queryResponse: Array<any> = queryList.queryResponse;
            let queryResponseGroupedByDate: Array<{ date: string; responses: Array<any> }> = [];
            queryResponse.forEach((x: any) => {
              let index = queryResponseGroupedByDate.findIndex((y: any) => formatDate(y.date, 'yyyy-MM-dd', 'en-US') == formatDate(x.createdAt, 'yyyy-MM-dd', 'en-US'))
              if (index >= 0) {
                queryResponseGroupedByDate[index].responses.push(x);
              } else {
                queryResponseGroupedByDate.push({
                  date: x.createdAt,
                  responses: [x]
                });
              }
            });
            queryList['queryResponseGroupedByDate'] = queryResponseGroupedByDate;
          }
          resolve(queryList || null);
        },
        (error: HttpErrorResponse) => {
          resolve(null);
        }
      );
    });
  }

  getQueryDetailForSeller(queryId: string): Promise<any> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.queryDetailForSeller(queryId), httpInput).subscribe(
        ({ queryList }) => {
          if (queryList && queryList.queryResponse && queryList.queryResponse.length) {
            let queryResponse: Array<any> = queryList.queryResponse;
            let queryResponseGroupedByDate: Array<{ date: string; responses: Array<any> }> = [];
            queryResponse.forEach((x: any) => {
              let index = queryResponseGroupedByDate.findIndex((y: any) => formatDate(y.date, 'yyyy-MM-dd', 'en-US') == formatDate(x.createdAt, 'yyyy-MM-dd', 'en-US'))
              if (index >= 0) {
                queryResponseGroupedByDate[index].responses.push(x);
              } else {
                queryResponseGroupedByDate.push({
                  date: x.createdAt,
                  responses: [x]
                });
              }
            });
            queryList['queryResponseGroupedByDate'] = queryResponseGroupedByDate;
          }
          resolve(queryList || null);
        },
        (error: HttpErrorResponse) => {
          resolve(null);
        }
      );
    });
  }

  submitSupport(payload: Support) {
    let savedData = this.storage.getSavedData();
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.post(Endpoints.common.support, payload, httpInput).subscribe(
        (res: any) => {
          resolve(res || null);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }

  getContent(): Promise<Array<any>> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.content, httpInput).subscribe(
        ({ contentList }) => {
          resolve(contentList || []);
        },
        (error: HttpErrorResponse) => {
          resolve([]);
        }
      );
    });
  }

  markQueryAs(payload: any, queryId: string) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.put(Endpoints.common.mrkCncl(queryId), payload, httpInput).subscribe(
        (res: any) => {
          resolve(res || null);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });

  }
  maintainCompareProductId(productId: string, action: 'add' | 'remove' | 'get') {
    let productsForComparison: Array<string> = (JSON.parse(sessionStorage.getItem(Session_Storage_Keys.compareProducts) as any) as any) || [];
    if (productId && productsForComparison && productsForComparison instanceof Array) {
      if (productsForComparison.length > 3) {
        productsForComparison.splice(3, productsForComparison.length - 3)
      }
      if ((action == 'add') && productId && !productsForComparison.includes(productId)) {
        if (productsForComparison.length >= 3) {
          this.showWarningToastr('You have already selected 3 products for comparison!');
        } else {
          productsForComparison.push(productId);
        }
      } else {
        if (action == 'remove' && productId) {
          productsForComparison.splice(productsForComparison.findIndex(x => x == productId), 1);
        }
      }
    }
    sessionStorage.setItem(Session_Storage_Keys.compareProducts, JSON.stringify(productsForComparison));
    if (action !== 'get') {
      this.addedToCompareSignal.next(true);
    }
    return productsForComparison;
  }

  states() {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.states, httpInput).subscribe(
        (res: any) => {
          resolve(res || null);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });

  }


  cityes(countryCode: string, isoCode: string) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.city(countryCode, isoCode), httpInput).subscribe(
        (res: any) => {
          resolve(res || null);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }

  getReviewsOnProduct(productId: string): Promise<any[]> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set('productId', productId);
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.reviewsList, httpInput).subscribe(
        ({ data }: { data: any[] }) => {
          resolve(data || []);
        },
        (error: HttpErrorResponse) => {
          resolve([]);
        }
      );
    });
  }

  fetchPincode(pincode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`${environment.pincodeLookupApi}${pincode}`).then((response) => response.json()).then(
        (response) => {
          if (response && response instanceof Array && response.length) {
            response = response[0];
            if (response.Status == 'Success') {
              let { PostOffice }: { PostOffice: Array<any> } = response;
              if (PostOffice && PostOffice.length) {
                resolve(PostOffice[0]);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        }
      ).catch(
        (error) => {
          resolve(null);
        }
      );
    });
  }


  share(share: 'product', id: string) {
    switch (share) {
      case 'product':
        navigator.share({
          "url": `${location.origin}/_/product/overview/${id}`
        }).finally(
          () => { }
        );
        break;
    }
  }

  searchProductOrService(query: SearchQuery): Promise<Array<any>> {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    for (let [key, value] of Object.entries(query)) {
      httpParams = httpParams.set(key, value);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.common.search, httpInput).subscribe(
        ({ productList }) => {
          resolve(productList || []);
        },
        (error) => {
          resolve([]);
        }
      );
    });
  }

  checkShippingAvailability(deliveryPostCode: number, pickupPostcode: number, weight: number) {
    let payload = {
      "pickup_postcode": pickupPostcode,
      "delivery_postcode": deliveryPostCode,
      "weight": weight
    }
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    for (let [key, value] of Object.entries(payload)) {
      httpParams = httpParams.set(key, value);
    }
    httpInput.params = httpParams;
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(Endpoints.customer.order.checkShippingAvailability, httpInput).subscribe(
        (res) => {
          resolve(res || null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  convertEmailToLowerCase(payload: any, page: "seller-registration-basic-info" |
    "seller-registration-other" |
    "login" |
    "forgot-password" |
    "buyer-registration" |
    "buyer-profile-email-update" |
    "seller-profile-email-update" |
    "buyer-profile-individual-form" |
    "buyer-profile-institution-form" |
    "seller-profile"
  ) {
    switch (page) {
      case 'seller-registration-basic-info':
        if (payload && payload.basicInfo) {
          payload.basicInfo.emailId = (payload.basicInfo.emailId || '').toLowerCase();
          payload.basicInfo.spocEmail = (payload.basicInfo.spocEmail || '').toLowerCase();
        }
        return payload;
        break;

      case 'seller-registration-other':
        if (payload && payload.bankDetails)
          payload.bankDetails.accountholderEmailId = (payload.bankDetails.accountholderEmailId || '').toLowerCase();
        return payload;
        break;

      case 'login':
        if (payload && payload.emailId)
          payload.emailId = (payload.emailId || '').toLowerCase();
        return payload;
        break;

      case 'forgot-password':
        if (payload && payload.emailId)
          payload.emailId = (payload.emailId || '').toLowerCase();
        return payload;
        break;

      case 'buyer-registration':
        if (payload) {
          payload.emailId = (payload.emailId || '').toLowerCase();
          payload.alternateEmailId = (payload.alternateEmailId || '').toLowerCase();
        }
        return payload;
        break;

      case 'buyer-profile-individual-form':
      case 'buyer-profile-institution-form':
        payload.emailId = payload.emailId ? payload.emailId.toLowerCase() : payload.emailId;
        payload.alternateEmailId = payload.alternateEmailId ? payload.alternateEmailId.toLowerCase() : payload.alternateEmailId;
        return payload;
        break;

      case 'buyer-profile-email-update':
        return (payload || '').toLowerCase();
        break;

      case 'seller-profile-email-update':
        return (payload || '').toLowerCase();
        break;

      case 'seller-profile':
        if (payload && payload.basicInfo) {
          payload.basicInfo.emailId = (payload.basicInfo.emailId || '').toLowerCase();
          payload.basicInfo.spocEmail = (payload.basicInfo.spocEmail || '').toLowerCase();
        }
        if (payload && payload.bankDetails)
          payload.bankDetails.accountholderEmailId = (payload.bankDetails.accountholderEmailId || '').toLowerCase();
        return payload;
        break;

      default:
        break;
    }
  }

}




