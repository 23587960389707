import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Storage_Keys } from './services/core/storage/storage.keys';
import { StorageService } from './services/core/storage/storage.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'e-samridh';

  constructor(
    private swUpdate: SwUpdate,
    private storage: StorageService,
    private router: Router
  ) {
    if (swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(
        (update) => {
          if (update) {
            this.swUpdate.activateUpdate();
          }
        }
      )
    }
  }

  ngOnInit(): void {
    let savedData = this.storage.getSavedData();
    window.addEventListener('storage', (event) => {
      let savedDataAfterChanged = this.storage.getSavedData();
      if (event) {
        if (savedData && savedData['authToken'] && savedData['userData']) {
          if ((event.key == 'authToken' && !savedDataAfterChanged['authToken']) || (event.key == 'userData' && !savedDataAfterChanged['userData'])) {
            window.location.reload();
          }
        } else {
          if ((event.key == 'authToken' && savedDataAfterChanged['authToken']) || (event.key == 'userData' && savedDataAfterChanged['userData'])) {
            window.location.reload();
          }
        }
        savedData = this.storage.getSavedData();
      }
    });

  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          let node = document.getElementById('sticky-buttons');

          if (node) {
            document.body.classList.add('sticky-footer');
          } else {
            document.body.classList.remove('sticky-footer');
          }
        }, 100);
      }
    });
  }

}
