// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  baseUrl: "https://api.test.e-samridh.com",
  VAPID: {
    "publicKey": "BLt4K5PANaQKiDTLbix52habSDJUpYw5U-BUtyxK9b-chD-g23PfSrn5OOrWjFLdddD8bQI36iHosql3JXfjjzQ",
    "privateKey": "SpmT6u7Z60wxzt5H6wndjuuChG6dah0w6Q446kqb5Xs"
  },
  pincodeLookupApi: "https://api.postalpincode.in/pincode/",
  paymentenv: "test",
  redirectUrls: {
    "redirect_url": "https://api.test.e-samridh.com/customer/v1/responseHandler",
    "cancel_url": "https://api.test.e-samridh.com/customer/v1/responseHandler",
  }
  // mapKey:"AIzaSyCoUGU_dE0Xdh6eIjXwqxYS3H6GueMgprw",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  /// Included with Angular CLI.
